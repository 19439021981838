var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar',{attrs:{"navcolor":'light-white'}}),_c('div',{directives:[{name:"scroll-spy",rawName:"v-scroll-spy"}]},[_c('section',{staticClass:"hero-4 position-relative bg-light",style:({
        'background-image':
          'url(' + require('@/assets/images/hero-4-bg.jpg') + ')',
      }),attrs:{"id":"home"}},[_c('div',{staticClass:"bg-overlay bg-dark"}),_c('div',{staticClass:"bg-overlay-img",style:({
          'background-image':
            'url(' + require('@/assets/images/hero-4-overlay.png') + ')',
        })}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-lg-4 ms-lg-auto text-center mt-lg-0 mt-5"},[_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.modal",modifiers:{"modal":true}}],staticClass:"d-inline-block"},[_c('a',{staticClass:"play-icon-circle video-play-icon",attrs:{"href":"javascript: void(0);"}},[_c('play-icon',{staticClass:"icon ps-1"})],1)]),_c('b-modal',{attrs:{"id":"modal","hide-footer":"","size":"lg","centered":"","header-close-variant":"white"}},[_c('youtube',{ref:"youtube",attrs:{"video-id":"RnDC9MXSqCY","width":"750","height":"450"}})],1)],1)])])]),_c('Services'),_c('Features'),_c('Pricing'),_c('Team'),_c('Blog'),_c('Contact'),_c('Footer')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-5"},[_c('span',{staticClass:"badge badge-soft-primary mb-4"},[_vm._v("Creative Design")]),_c('h1',{staticClass:"font-weight-semibold mb-4 hero-4-title"},[_vm._v(" We Help Startups Launch Their Products ")]),_c('p',{staticClass:"mb-5 text-muted"},[_vm._v(" Nemo enim ipsam voluptatem quia voluptas sit aut aspernatur aut fugit sed quia consequuntur magni dolores ratione voluptatem nesciunt. ")]),_c('a',{staticClass:"btn btn-lg btn-primary me-2",attrs:{"href":"#"}},[_vm._v("Get Started")]),_c('a',{staticClass:"btn btn-lg btn-soft-primary me-2",attrs:{"href":"#"}},[_vm._v("Try for free")])])
}]

export { render, staticRenderFns }